
import React, { Component } from "react";
import LogoImg from "../images/AISTO-LOGO.png"
import FbImg from "../images/f_logo_RGB-Black_58.png"
import IgImg from "../images/instagram-loco-black.png"

export default class Footer extends Component {

  render() {

  return (
    <footer className="pt-4 mx-auto col-xl-4 col-lg-4 col-md-5 col-sm-6 col-xs-8 text-center">
    <img src={LogoImg} className="img-responsive w-25 mx-auto pb-3" alt="Aisto" style={{display: 'block'}}/>
    <p> 
      Juuso Lahtinen
    </p>
      
    <p>
      p. +358 458925228 <br />
      juuso.lahtinen@aisto.fi  <br />
    </p>
    <p>
      Y-tunnus: 3007015-7
    </p> 
    <div className="row justify-content-center">
      <a href="https://www.facebook.com/aisto.fi"> <img src={FbImg} className="img-responsive mx-2" alt="FB" style={{display: 'block'}} width="35em" height="35em"/> </a>
      <a href="https://instagram.com/aisto.fi"> <img src={IgImg} className="img-responsive mx-2" alt="IG" style={{display: 'block'}} width="35em" height="35em"/> </a>
    </div>
    © AISTO {new Date().getFullYear()}
    <a href="/privacy" style={{color: 'grey', fontSize: '1em' }}> <p> GDPR </p> </a>
  </footer>
  );
  }
}